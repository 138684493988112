@import '~theme/variables';

.wrapper {
  position: relative;
  border-radius: $border-radius-05;
  overflow: hidden;
  margin-right: $space-s;
  display: block;
  word-break: break-word;

  &.isPanel {
    margin-left: $space-s;
  }

  &:not(.isPanel) {
    cursor: pointer;

    &:hover .backgroundImage {
      transform: scale(110%);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: filter 0.2s ease-in-out;
  }
}

.backgroundImage {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.container {
  position: relative;
  z-index: $z-index-above;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-03;
  background: linear-gradient(
    360deg,
    rgba(16, 15, 13, 1) 0%,
    rgba(217, 217, 217, 0) 54.17%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: $space-m;
}

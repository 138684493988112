@import '~theme/variables';

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint(sm) {
    gap: 2.4rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint(lg) {
    gap: 2.4rem;
    grid-template-columns: repeat(4, 1fr);
  }
}

.header {
  margin-bottom: $space-m;
}

.cardDetail {
  margin: 0;
  aspect-ratio: 2/3;
  width: 100%;

  img {
    border-radius: $border-radius-03;
  }

  @include breakpoint(sm) {
    aspect-ratio: 0.7268;
  }
}
